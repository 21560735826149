import React from 'react'
import Image from 'gatsby-image'
import {
  LandingTransition,
  PageTransition,
  SpeiseTransition,
} from '../components/transition'
import { graphql } from 'gatsby'
import RehypeReact from 'rehype-react'
import styled from '@emotion/styled'

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    li: ({ children }) => <li itemProp="ingredients">{children}</li>,
    p: ({ children }) => <p itemProp="recipeInstructions">{children}</p>,
  },
}).Compiler

const Container = styled.div({
  backgroundColor: 'white',
  marginTop: '129px',
})

export default ({ data }) => {
  const speise = data.markdownRemark
  return (
    <SpeiseTransition>
      <Container itemType="http://schema.org/Recipe">
        <Image
          itemProp="image"
          fixed={speise.frontmatter.cover_image.childImageSharp.fixed}
        />
        <h1 itemProp="name">{speise.frontmatter.title}</h1>
        {renderAst(speise.htmlAst)}
        <a href={speise.frontmatter.link}>{speise.frontmatter.link}</a>
      </Container>
    </SpeiseTransition>
  )
}

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
        link
        cover_image {
          publicURL
          childImageSharp {
            fixed(width: 500) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
